import React from 'react'
import Layout from '../components/Layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import TitleArea from '../components/TitleArea'
import { Container, Columns, Column, Content, Title, Section } from 'bloomer'

export default ({ data }) => (
  <Layout>
    <SEO title="Cat Boarding" alias="cat-boarding" />
    <TitleArea
      title="Cat Boarding"
      subtitle="Your pet’s comfort is our priority."
    />
    <Section>
      <Container className="container--narrow">
        <Columns>
          <Column>
            <Content>
              <p>
                We are able to accommodate our guests in individual large condos.
              </p>

              <p>
                The cat room at the front of the kennel has two large sunny
                windows and a large area where your cat may roam.
              </p>

              <p>
                Guests are allowed to roam individually or with their family
                members throughout the day. Cats from different homes are never
                mixed.
              </p>
            </Content>
            <Title
              tag="h2"
              // hasTextAlign="centered"
            >
              Pricing
            </Title>
            <Content>
              <table class="table pricing-table">
                <tbody>
                  <tr>
                    <th>1 Cat</th>
                    <td>$12 a night</td>
                  </tr>
                  <tr>
                    <th>2 Cats</th>
                    <td>$21 a night</td>
                  </tr>
                  <tr>
                    <th>3 Cats</th>
                    <td>$32 a night</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2">Each additional cat is $10 a night</td>
                  </tr>
                </tfoot>
              </table>
            </Content>
          </Column>

          <Column isSize="narrow">
            <div>
              <Img fixed={data.image1.childImageSharp.fixed} />
            </div>
            <Img fixed={data.image2.childImageSharp.fixed} />
          </Column>
        </Columns>
        <Columns>
          <Column>
            <Content>
              <ul>
                <li>
                  All rates are charged per night. If you brought your pet on
                  Friday, for example, and then picked it up during our Sunday
                  hours, you would be charged for 2 nights
                </li>
                <li>
                  Families <Link to="/dog-boarding/">boarding dogs</Link> as
                  well as cats have special rates. 1 cat and 1 dog are only $33
                  per night
                </li>
                <li>Tax not included in rates</li>
              </ul>
              <h2>Boarding Agreement</h2>
              <p>
                A standard{' '}
                <Link to="/boarding-kennel-agreement/">
                  boarding kennel agreement
                </Link>{' '}
                is signed by both Shady Lane Boarding Kennel and the Pet Owner
                upon arrival.
              </p>
              <h2>Vaccinations</h2>
              <p>
                We have a{' '}
                <Link to="/vaccination-policy/">vaccination policy</Link> that
                includes bordetella and proper documentation is required at each
                visit to the kennel.
              </p>
            </Content>
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cat-boarding/image-1.jpg" }) {
      childImageSharp {
        fixed(width: 420, height: 420) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image2: file(relativePath: { eq: "cat-boarding/image2.jpg" }) {
      childImageSharp {
        fixed(width: 420, height: 308) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
